import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Atlanta SEO Website Design - LTCPG Case Study - Websuasion"
        ogDescription="Are you looking for a professional seo website designer serving Atlanta, GA? See how we enabled The Long Term Care Planning Group to achieve their lead generation goals."
        image="/images/case-studies/atlanta-seo-website-design.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>App Development Atlanta</h1>
						<h3>A Case Study of Sionic Mobile</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled The Long Term Care Planning Group to achieve their app development goals by providing SEO keyword research and strategy, web design, video production, salesforce integration, and ongoing support.
            </p>
						<p>
							Are you looking for a professional web developer serving the midtown Atlanta GA area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/atlanta-seo-website-design.jpg" className="rounded-image" alt="Atlanta SEO Website Design - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/ltcpg-logo.webp" alt="Atlanta SEO Website Design - Websuasion" />
							</p>
			        <p>
								Based in Atlanta, Georgia. Serving the Nation. The Long Term Care Planning Group has focused on long term care planning since 2001. We have relationships with all the major carriers in the long term care marketplace. We work with only A.M. Best Rating A+ carriers to find the best match based on your medical history. We've been fortunate to place thousands of clients all over the United States.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Websites serving marketing content for differing audiences</li>
			        <li>Mobile optimized websites</li>
							<li>Solution enabling integration to Salesforce CRM</li>
							<li>Webinar and Video content</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>Four unique desktop, tablet, and mobile responsive websites</li>
			        <li>Marketing content and styling to prospects and industry partners</li>
							<li>Custom ReactJS form integration with Salesforce API</li>
							<li>Scripting, filming, editing, and output for various video platforms</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>SEO Keyword Research and Strategy</h2>
							<p>
								We provided robust keyword research using our 26-data-point to determine the most valuable keywords. The result was a content strategy that exceeded LTCPG's expectations.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>SEO Content</h2>
							<p>
								We developed detailed and informative website content and articles based upon the keyword research and strategy. This consistently has LTGCP showing up in the top of local and national search results for the long term care industry.
							</p>
							<p>
								<img src="/images/case-studies/ltcpg-search-results.webp" alt="Atlanta SEO Website Design - Websuasion" />
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Website Design and Salesforce Integration</h2>
							<p>
								We created a fast and responsive ReactJs/GatsbyJs based website for The Long Term Care Planning Group that seamlessly integrates with their Salesforce CRM and advertising platforms.
							</p>
							<p>
								<img src="/images/case-studies/ltcpg.webp" alt="Atlanta SEO Website Design - Websuasion" />
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Long Format Video and Webinar Content</h2>
							<p>
								Evergreen Webinars played a big role in the lead generation strategy for LTCPG, creating a consistent flow of prospects to their CRM. Additionally, we created long-format informative videos answering the pain points of their customers.
							</p>
							<p>
			          <ReactPlayer
			            url= 'https://www.youtube.com/watch?v=-TuahM35df8'
			          />
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>
								On a continual basis, we provide content maintenance, data management, technical support, and hosting of the existing site.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
